<template>
  <div class="hk-content">
    <ul class="hk-login" :class="{'hk-login_email': isEmail}">
      <li v-if="!isEmail" class="hk-login__sign-up hk-desktop">
        <p>{{ $t('login.notRegist') }}</p>
        <p>{{ $t('login.registNow') }}</p>
        <el-button type="primary" @click="showRegistDialog = true">{{ $t('login.register') }}</el-button>
      </li>
      <li class="hk-login__sign-in">
        <div class="hk-login__form">
          <h4 v-html="$t('login.title')" />
          <el-input v-model="data.username" :placeholder="$t('login.emailDesc')">
            <i slot="prefix" class="iconfont icon-email" />
          </el-input>
          <el-input v-model="data.password" type="password" :placeholder="$t('login.password')">
            <i slot="prefix" class="iconfont icon-password" />
          </el-input>
          <div class="hk-checkbox">
            <el-checkbox v-model="keep">{{ $t('login.remember') }}</el-checkbox>
            <a class="hk-login__index" @click="goHome">{{ $t('login.index') }}</a>
            <a class="hk-login__forgot" @click="showForgetDialog = true">{{ $t('login.forgot') }}</a>
          </div>
          <el-button type="primary" @click="login">{{ $t('login.homepageLogin') }}</el-button>
        </div>
      </li>
      <li v-if="!isEmail" class="hk-login__sign-up hk-mobile">
        <p>{{ $t('login.notRegist') }}</p>
        <p>{{ $t('login.registNow') }}</p>
        <el-button type="primary" @click="showRegistDialog = true">{{ $t('login.register') }}</el-button>
      </li>
    </ul>

    <el-dialog class="hk-regist-dialog" :visible.sync="showForgetDialog">
      <template v-if="!hasSendEmail">
        <el-form :model="forget" ref="forget" label-width="0px" class="demo-dynamic">
          <el-form-item
            prop="email"
            :rules="[
				      { required: true,  message: $t('forms.required'), trigger: 'blur' },
              { type: 'email', message: $t('forms.emailTypeError'), trigger: 'blur' },
				    ]"
          >
            <el-input v-model="forget.email" :placeholder="$t('login.registEmail')" />
          </el-form-item>
          <el-form-item prop="sms">
            <div class="hk-sms">
              <el-input v-model="forget.sms" :placeholder="$t('login.sms')" />
              <el-button
                v-if="!smsButton"
                type="primary"
                @click="sendSMS(forget.email, 'RESET')"
              >{{ $t('login.sendSms') }}</el-button>
              <el-button v-if="smsButton" type="info" disabled>{{ buttonCounter }}</el-button>
            </div>
          </el-form-item>
        </el-form>
      </template>
      <template v-else>
        <i class="iconfont icon-success" />
        <p>{{ $t('login.hasSendEmail') }}</p>
      </template>
      <el-button
        :disabled="( disableButton || forgetCheck )"
        type="primary"
        @click="forgetPassword"
      >{{ $t('common.confirm') }}</el-button>
    </el-dialog>

    <el-dialog class="hk-regist-dialog" :visible.sync="showRegistDialog">
      <el-form :model="regist" ref="regist" class="demo-dynamic">
        <el-form-item
          prop="email"
          :rules="[
          { required: true,  message: $t('forms.required'), trigger: 'blur' },
          { type: 'email',  message: $t('forms.emailTypeError'), trigger: ['blur', 'change'] }
          ]"
        >
          <el-input v-model="regist.email" :placeholder="$t('login.registEmail')" />
        </el-form-item>

        <div class="hk-sms">
          <el-input v-model="regist.sms" :placeholder="$t('login.sms')" />
          <el-button
            v-if="!smsButton"
            type="primary"
            @click="sendSMS(regist.email, 'REGISTER')"
          >{{ $t('login.sendSms') }}</el-button>
          <el-button v-if="smsButton" type="info" disabled>{{ buttonCounter }}</el-button>
        </div>
      </el-form>
      <el-button
        type="primary"
        :disabled="( disableButton || registCheck )"
        @click="verifyCode"
      >{{ $t('common.confirm') }}</el-button>
    </el-dialog>

    <el-dialog class="hk-regist-dialog" :visible.sync="showAlertDialog">
      <p>{{ alertMessage }}</p>
      <el-button type="primary" @click="showAlertDialog = false">{{ $t('common.confirm') }}</el-button>
    </el-dialog>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
export default {
  name: 'HkLogin',
  data() {
    let pw = ''
    let localStorageUserTk = ''

    if (
      Boolean(localStorage.getItem('isRememberMe')) &&
      localStorage.getItem('usermail')
    ) {
      localStorageUserTk = localStorage.getItem('UserTk')
      let bytes = CryptoJS.AES.decrypt(
        localStorageUserTk,
        localStorage.getItem('usermail')
      )
      pw = bytes.toString(CryptoJS.enc.Utf8)
    } else {
      pw = ''
    }
    return {
      data: {
        username: Boolean(localStorage.getItem('isRememberMe'))
          ? localStorage.getItem('usermail')
          : '',
        password: pw,
      },
      regist: {
        email: '',
        sms: '',
      },
      forget: {
        email: '',
        sms: '',
      },
      keep: Boolean(localStorage.getItem('isRememberMe')),
      showRegistDialog: false,
      showForgetDialog: false,
      disableButton: this.buttonCompute,
      hasSendEmail: false,
      token: localStorage.getItem('SSID'),
      config: {
        error: false,
        wrongCode: 'wrong verification code',
        language: this.currentLanuage,
      },
      alertMessage: '',
      showAlertDialog: false,
      smsButton: false,
      buttonCounter: 0,
    }
  },
  computed: {
    isEmail() {
      return this.$route.query.email
    },
    registCheck() {
      let self = this
      let status = false
      if (self.regist.email.length == 0 || self.regist.sms.length == 0) {
        status = true
      }
      return status
    },
    forgetCheck() {
      let self = this
      let status = false
      if (self.forget.email.length == 0 || self.forget.sms.length == 0) {
        status = true
      }
      return status
    },
  },
  methods: {
    async login() {
      let self = this
      let md5 = require('md5')
      let encryptPwd = md5(self.data.password)
      let userToken = ''
      if (self.keep) {
        let ciphertext = CryptoJS.AES.encrypt(
          self.data.password,
          self.data.username
        ).toString()
        localStorage.setItem('UserTk', ciphertext)
        localStorage.setItem('isRememberMe', true)
        localStorage.setItem('usermail', self.data.username)
      } else {
        localStorage.setItem('usermail', '')
        localStorage.setItem('isRememberMe', '')
      }
      // USER REGISTRATION => SEND VERIFICATION CODE 邮箱注册接口
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/login?'
      let res = await this.axios
        .post(apiCall, {
          lang: self.$store.state.config.language,
          email: self.data.username,
          password: encryptPwd,
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.userToken = response.data.data.token
              localStorage.setItem('SSID', self.userToken)
              self.getStatus()
              break
            case 2004:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.login.wrongPassword'
              )
              break
            case 2017:
              self.showAlertDialog = true
              self.alertMessage = response.data.msg
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    async getStatus() {
      let self = this
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/info?lang='
      let res = await this.axios
        .get(apiCall, {
          headers: {
            token: localStorage.getItem('SSID'),
          },
        })
        .then(function (response) {
          let userId = response.data.data.id
          let userNum = response.data.data.number
          let reviewStatus = response.data.data
          self.$store.commit('changeUserIdentity', reviewStatus)
          switch (reviewStatus.pass) {
            case 'SUC':
            case 'WAIT':
              localStorage.setItem('HKSTS__userId', userId)
              localStorage.setItem('HKSTS__userNum', userNum)
              self.updateCartNum()
              self.goRouter('home')
              break
            case 'FAIL':
              self.showAlertDialog = true
              self.alertMessage = self.$t('login.alertMessage.login.reviewFail')
              break
            case 'UNSUBMIT':
              self.goRouter('regist')
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    goRouter(alias) {
      this.$router.push({
        name: alias,
      })
    },
    sendSMS(email, type) {
      let self = this
      let validateType = 'forget'
      if (type == 'REGISTER') {
        validateType = 'regist'
      } else {
        validateType = 'forget'
      }
      let validate = false
      this.$refs[validateType].validate((valid) => {
        if (valid) {
          validate = true
        } else {
          alert(self.$t('forms.missingData'))
          return false
        }
      })
      if (!validate) {
        return false
      }
      self.smsButtonToggler()
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/sendCode?'
      this.axios
        .post(apiCall, {
          // lang always = zh
          lang: 'zh',
          email: email,
          type: type,
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showAlertDialog = true
              self.alertMessage = self.$t('login.alertMessage.sendSMS.success')
              break
            default:
              self.showAlertDialog = true
              self.alertMessage = response.data.msg
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    smsButtonToggler() {
      let self = this
      self.smsButton = true
      self.buttonCounter = 60
      var counter = setInterval(function () {
        if (self.buttonCounter === 0) {
          clearInterval(counter)
          self.smsButton = false
        } else {
          self.buttonCounter--
        }
      }, 1000)
    },
    updateCartNum() {
      let self = this
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        },
      }
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/shopping/productNum'
      this.axios
        .get(apiCall, header)
        .then(function (response) {
          self.$store.commit('updateCartNum', response.data.data.product_num)
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    handleSendEmail() {
      if (this.hasSendEmail) {
        this.showForgetDialog = false
      } else {
        this.hasSendEmail = true
      }
    },
    handleForgetDialog() {
      this.hasSendEmail = false
      this.showForgetDialog = true
    },
    verifyCode() {
      // USER REGISTRATION => SEND VERIFICATION CODE 邮箱注册接口
      let self = this
      self.disableButton = true
      let rootApi = self.$store.state.config.apiRoot
      let apiCall =
        rootApi + '/api/user/register?lang=' + self.$store.state.config.language
      this.axios
        .post(apiCall, {
          email: this.regist.email,
          code: this.regist.sms,
        })
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.getPassword.success'
              )
              self.showRegistDialog = false
              self.disableButton = false
              self.smsButton = false
              break
            case 2001:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.getPassword.emailExist'
              )
              self.disableButton = false
              self.smsButton = false
              break
            default:
              self.showAlertDialog = true
              self.alertMessage = response.data.msg
              self.disableButton = false
              self.smsButton = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    forgetPassword() {
      let self = this
      self.disableButton = true
      let rootApi = self.$store.state.config.apiRoot
      let apiCall = rootApi + '/api/user/resetPassword'
      let formData = new FormData()
      formData = {
        email: self.forget.email,
        code: self.forget.sms,
      }
      this.axios
        .post(apiCall, formData)
        .then(function (response) {
          let code = response.data.code
          switch (code) {
            case 0:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetPassword.success'
              )
              self.showForgetDialog = false
              self.disableButton = false
              self.smsButton = false
              break
            case 2002:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetPassword.invalidCode'
              )
              self.disableButton = false
              self.smsButton = false
              break
            case 2023:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetPassword.notRegister'
              )
              self.disableButton = false
              self.smsButton = false
              break
            default:
              self.showAlertDialog = true
              self.alertMessage = self.$t(
                'login.alertMessage.resetPassword.fail'
              )
              self.disableButton = false
              self.smsButton = false
              break
          }
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    goHome() {
      this.$router.push({
        name: 'home',
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import './styles/index';
</style>
<style>
.el-button:disabled {
  background-color: gray !important;
}
</style>
